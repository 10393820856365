import React from "react";
import { Link } from "react-router-dom";
import "../styles/Program.css";

const Card = ({ person, size, title, content1, content2, content3, imageSrc, ribbonText }) => {
  return (
    <div className="card">
      {
        <div className="program-box">
          <div className="ribbon ribbon-top-right">
            <span>{ribbonText}</span>
          </div>
          <img className="card-image" alt="1-op-1" src={imageSrc}></img>
          <h2 className="card-title">{title}</h2>
          <p className="card-text">{content1}</p>
          <p className="card-text">{content2}</p>
          <p className="card-text">{content3}</p>
          <Link className="card-link" to="/contact">
            <button className="card-button"> PROEFLES AANVRAGEN </button>
          </Link>
        </div>
      }
    </div>
  );
};

export default Card;
