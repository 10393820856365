import React from "react";
import "../styles/Tarieven.css";
import Program from "../components/Program.js";
import SingleClass from "../assets/single-class.jpg";
import DuoClass from "../assets/duo-class.jpg";

function Tarieven() {
  return (
    <div className="programs-container">
      <div className="text-container">
        <h1>Aanbod</h1>
        <p>
          Suikergym staat voor betrokken en persoonlijke begeleiding. Dat gaat
          verder dan het ene uurtje in de week dat jij komt sporten. Wil je je
          leefstijl veranderen, dan denken we graag met je mee over schema's
          waar jij in je eigen tijd mee aan de slag kan, in de gym en in de
          keuken!
        </p>
      </div>
      <div className="card-container">
        <title>{(document.title = "Programs - Suikergym")}</title>
        <Program
          title="1-op-1"
          content1="Extra aandacht, ook buiten de gym"
          content2="Verbeterde focus"
          content3="Krachtige motivatie"
          person={{ name: "single-class", imageId: "singleClass" }}
          imageSrc={SingleClass}
          ribbonText="€ 50,- per uur"
        />
        <Program
          title="Duoles"
          content1="Gezellig samen sporten met je buddy"
          content2="Zelfde kwaliteit, lagere prijs"
          content3="Extra motiverend"
          person={{ name: "duo-class", imageId: "duoClass" }}
          imageSrc={DuoClass}
          ribbonText="€ 40,- p.p. per uur"
        />
      </div>
    </div>
  );
}

export default Tarieven;
